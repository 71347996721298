import GuideAssistant from "./components/scrollGuide"
import LandingView from "./views/LandingView"
import HonorView from "./views/HonorView"
import LocationView from "./views/LocationView"
import "./FrameStyle.css"
import React, {useRef, useState} from "react";
import useOnScreen from "./components/useOnScreen";
import RSVP from "./views/RSVP";
import CompleteView from "./views/CompleteView";
import TransitImage from "./views/TransitImage";
import { motion , AnimatePresence} from "framer-motion"
import webService from "./../services/webservice"

type ORIENTATION_TYPE = "Landscape" | "Portrait";
const Frame = () => {
    const [ws] = useState(new webService());
    const [bottomStatus, reachedBottom] = useState(true);
    const [status, setStatus] = useState('entry');
    const landingDiv = useRef<any>(null);
    const completeDiv = useRef<any>(null);
    const bottomDiv = useRef<any>(null);
    const onLanding = useOnScreen(landingDiv) || false;
    const onComplete = useOnScreen(completeDiv) || false;
    const onBottom = useOnScreen(bottomDiv) || false;


    if (onBottom && bottomStatus && ws.clientSession.getSessionTime() > 1000){
        reachedBottom(false);
    }
    function rsvpCompleted() {
        setStatus('complete');
        document.getElementById("Confirmation")?.scrollIntoView();
    }
    function rescindRSVPClick() {
        let email = "jonathan.r.doles@gmail.com";
        let subject = "Rescind%20Baby%20Shower%20Response";
        let body = "Oh%2C%20we%20are%20sorry%20that%20you%E2%80%99re%20not%20going%20to%20be%20able%20to%20make%20it.%20%20Please%20enter%20the%20following%20information%20and%20we%20will%20remove%20all%20guest%20listed%20from%20the%20guest%20list.%20%0A%0AWho%20sent%20the%20RSVP%3A%20%28This%20person%20will%20not%20be%20moved%20unless%20listed%20below%29%0AList%20the%20names%20of%20the%20guest%20that%20are%20no%20longer%20attending%20the%20Baby%20Shower%3A%0A%0A%E2%80%A2%09%20%0A%E2%80%A2%09%20%0A%E2%80%A2%09%20%0A%E2%80%A2%09%20%0A%E2%80%A2%09%0A";
        window.open("mailTo:".concat(email,"?subject=", subject, "&body=", body),"_self");
    }
    return (
        <div className={"frame"}>
            <div className={"frame-image-top"}/>
            <div className={"frame-image-bottom"}/>
            <div className={"bg-color"}>
                <div className={"scrollable"}>
                    <section id={"landing"} ref={landingDiv}>
                        <LandingView/>
                    </section>
                    <TransitImage/>
                    <HonorView/>
                    <LocationView/>
                    <TransitImage/>
                    <section id={"RSVP"}  hidden={!(status === 'entry')}>
                        <RSVP complete={rsvpCompleted} webservice={ws}/>
                    </section>
                    <section
                        hidden={!(status === 'complete')}
                        id={"Confirmation"}
                        ref={completeDiv}>
                        <CompleteView/>
                    </section>
                    <div className={"bottom-gap"} ref={bottomDiv}></div>
                </div>
            </div>
            {onLanding.visible && bottomStatus && (<GuideAssistant/>)}
            {onBottom.visible && status === 'entry' && (
                <button id={"rescind-rsvp"} className={"font-secondary font-color-primary"} onClick={rescindRSVPClick}>Cancel RSVP</button>
            )}
            <AnimatePresence>
                {onComplete.visible && status === 'complete' && (
                    <motion.div
                        className={"finalMessage"}
                        initial={{ x: 300, opacity: 0 }}
                        animate={{ x: 0, opacity: 1 }}
                        exit={{ x: -300, opacity: 0 }}
                    >
                        <p className={"font-stylist"}>See you at the baby shower for</p>
                        <p className={"font-baby-name"}>Eliana Mary-Belle Doles</p>
                    </motion.div>
                )}
            </AnimatePresence>
        </div>
    )
}
export default Frame;