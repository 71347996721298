import "./ViewStyle.css"
import "./CompleteViewStyle.css"

const CompleteView = () => {
    return (
        <div className={"center"}>
            <img className={"babyImage"} alt={"baby"} src={"assets/images/baby.jpg"}/>
        </div>
    )
}
export default CompleteView;