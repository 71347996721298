import {RefObject, useEffect, useMemo, useState} from "react";

export default function useOnScreen(ref: RefObject<HTMLElement>) {

    const [isIntersecting, setIntersecting] = useState(false);

    const observer = useMemo(() => new IntersectionObserver(
        ([entry]) => {
            setIntersecting(entry.isIntersecting);
        }
        // eslint-disable-next-line
    ), [ref])

    useEffect(() => {
        if(ref !== null && !!ref.current) {
            observer.observe(ref.current as HTMLElement)
        }
        return () => observer.disconnect()
        // eslint-disable-next-line
    }, [])


    return { visible: isIntersecting}
}