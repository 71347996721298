import "./ViewStyle.css"
import "./HonorViewStyle.css"
import { motion } from "framer-motion";

const HonorView = () => {

    function registryClicked() {
        window.open("https://www.babylist.com/list/jonathan-erika-doles", "_blank");
    }

    return (
        <section id={"honor"}>
            <div className={"center"}>
                <p className={"font-caption font-color-primary"}>Honoring</p>
                <p id={"honor-caption"} className={"font-secondary-large font-color-secondary"}>Erika & Jonathan<br/>Doles</p>
                <motion.button
                    whileHover={{
                        scale: .8
                    }}
                    type={"button"} onClick={registryClicked}>
                    <p className={"font-caption font-color-primary"}>Check out our<br/>registry</p>
                </motion.button>
            </div>
        </section>
    )
}

export default HonorView;