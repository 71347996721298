import "./ViewStyle.css"
import "./LocationViewStyle.css"
import * as rdd from 'react-device-detect';

const LandingView = () => {

    function openMap() {
        let scheme = 'https://';
        let latLng ='35.20497675589218,-89.86728584741714';
        let label = 'The Baby shower!!!';
        let url = '';
        if (rdd.isMobile){
            if(rdd.isIOS){
                scheme = "maps://0,0?q=";
                url = scheme.concat(label,'@',latLng);
            }else if(rdd.isAndroid){
                scheme = "geo:0,0?q=";
                url = scheme.concat(latLng,'(',label,")'");
            }else {

            }
        }
        if(scheme === 'https://') {
            url = 'https://maps.app.goo.gl/5gp5KKf1PckcmKLn7';
        }
        window.open(url);
    }

    return (
        <section id={"location"}>
            <div className={"center"}>
                <p className={"font-caption font-color-primary "}>at</p>
                <p id={"location-name"} className={"font-secondary-large font-color-secondary"}>Bartlett Station<br/>Municipal Center</p>
                <p onClick={openMap} className={"font-caption font-color-primary clickable"}>5868 Stage Rd.<br/>Bartlett, Tn. 38134</p>
                <p id={"location-room"} className={"font-secondary font-color-secondary"}>Community Room A<br/>1pm - 3pm</p>
            </div>
        </section>
    )
}
export default LandingView;