import React from 'react';
import Frame from "./pages/Frame";
import './App.css';

function App() {
  return (
    <div className="App">
      <Frame></Frame>
    </div>
  );
}

export default App;
