import "./ViewStyle.css"
import "./RSVP.css"
import {FormEvent, useState} from "react";
import { motion } from "framer-motion";
import webservice from "../../services/webservice";
import { CircularProgress } from "@mui/material";

type guestId = 1|2|3|4|5;
export type messageLayout = {submitter: string, guests: string[]};
const RSVP = (prop:{complete: () => void, webservice: webservice}) => {
    const [count, setCount] = useState<guestId>(1);
    const [guest1, updateGuest1] = useState<string>('');
    const [guest2, updateGuest2] = useState<string>('');
    const [guest3, updateGuest3] = useState<string>('');
    const [guest4, updateGuest4] = useState<string>('');
    const [guest5, updateGuest5] = useState<string>('');
    const [failures, increaseFailureCount] = useState<number>(0);
    const [isLoading, updateLoadingStatus] = useState<boolean>(false);
    function adjust(guest: guestId, entry: string) {
        switch (guest){
            case 1: updateGuest1(entry); break;
            case 2: updateGuest2(entry); break;
            case 3: updateGuest3(entry); break;
            case 4: updateGuest4(entry); break;
            case 5: updateGuest5(entry); break;
        }
    }
    function removeUser(guest: guestId) {
        let newCount: guestId = count === 1 ? 1 : count - 1 as 2|3|4;
        if (count !== newCount){
            for (let i = guest; i < 5; i++){
                switch (i){
                    case 2: updateGuest2(guest3); break;
                    case 3: updateGuest3(guest4); break;
                    case 4: updateGuest4(guest5); break;
                }
            }
            updateGuest5('');
            setCount(newCount);
        }
    }
    function addUser() {
        let newCount: guestId = count === 5 ? 5 : count + 1 as 2|3|4|5;
        if (count !== newCount){
            if (getEntry(count) !== '') {
                setCount(newCount);
            }
        }
    }
    function getVaildEntries(): string[] {
        let guestList: string[] = [];
        switch (count){
            // @ts-ignore
            case 5: guestList.push(guest5);
            // @ts-ignore
            // eslint-disable-next-line no-fallthrough
            case 4: guestList.push(guest4);
            // @ts-ignore
            // eslint-disable-next-line no-fallthrough
            case 3: guestList.push(guest3);
            // @ts-ignore
            // eslint-disable-next-line no-fallthrough
            case 2: guestList.push(guest2);
            // eslint-disable-next-line no-fallthrough
            case 1: guestList.push(guest1);
                break;
        }
        return guestList.filter(e => (e !== null && e !== ''));
    }
    function getEntry(guest: guestId): string{
        switch (guest){
            case 1: return guest1;
            case 2: return guest2;
            case 3: return guest3;
            case 4: return guest4;
            case 5: return guest5;
            default: return '';
        }
    }

    function manaulRSVPClick() {
        let email = "jonathan.r.doles@gmail.com";
        let subject = "Baby%20Shower%20Manual%20RSVP";
        let body = "We%20are%20sorry%20you%E2%80%99re%20experiencing%20issue%20with%20RSVPing%20for%20the%20baby%20shower.%20%20Please%20enter%20your%20information%20below%20and%20we%20will%20manually%20add%20you%20and%20any%20guest%20listed%20below%20to%20the%20Guest%20List.%0A%0AYour%20Name%3A%0A%0AOther%20Guests%3A%0A%20%0A%E2%80%A2%09%20%0A%E2%80%A2%09%20%0A%E2%80%A2%09%20%0A%E2%80%A2%09%0A%0A%0A";
        window.open("mailTo:".concat(email,"?subject=", subject, "&body=", body),"_self");
    }

    function onSubmitAction(e: FormEvent<HTMLFormElement>) {
        e.preventDefault();
        updateLoadingStatus(true);
        let guestList: messageLayout = {submitter: guest1, guests: getVaildEntries()};
        prop.webservice.sendRSVP(guestList).then((response) =>{
                if(response === "SUCCESS"){
                    prop.complete();
                }else {
                    increaseFailureCount(prevState => prevState + 1);
                    alert('Ops, We were unable to process your request. Please try again later. Thanks, Erika & Jonathan');
                }
            updateLoadingStatus(false);
            }
        )
    }
    let validCount = getVaildEntries().length;

    if (failures >= 3){
        return (
            <div className={"center rsvp-form"}>
                <p className={"font-secondary-small font-color-primary"}>
                    Ops, our service seems to be down...
                    <br/>
                    Try again tomorrow or
                    <span style={{cursor: "pointer"}} onClick={manaulRSVPClick}> email us </span>
                    RSVP details...</p>
            </div>
        )
    }else if (isLoading){
        return (
            <div className={"center rsvp-form"}>
                <span className={"center"}>Processing RSVP<br/>for {validCount} guest{validCount > 1 ? "s." : "."}</span>
                <CircularProgress color="secondary"/>
            </div>
        )
    }else {
        return (
            <div className={"center rsvp-form"}>
                <p>RSVP: Count: {validCount}/{count}</p>
                <form onSubmit={onSubmitAction}>
                    <div>
                        <input id={"submitter"}
                               type={"text"}
                               className={"font-secondary-small font-color-primary"}
                               placeholder={"Your Name"}
                               value={getEntry(1)}
                               onChange={(e) => {
                                   adjust(1, e.target.value)
                               }}
                        />
                    </div>
                    {count >= 2 && (
                        <div>
                            <input
                                type={"text"}
                                className={"font-secondary-small font-color-primary"}
                                placeholder={"Guest Name"}
                                value={getEntry(2)}
                                onChange={(e) => {
                                    adjust(2, e.target.value)
                                }}
                            />
                            <motion.button whileHover={{
                                scale: 1.1,
                            }} type={"button"} className={"circleAction"} onClick={() => {
                                removeUser(2);
                            }}>X
                            </motion.button>
                        </div>)}
                    {count >= 3 && (
                        <div>
                            <input
                                type={"text"}
                                className={"font-secondary-small font-color-primary"}
                                placeholder={"Guest Name"}
                                value={getEntry(3)}
                                onChange={(e) => {
                                    adjust(3, e.target.value)
                                }}
                            />
                            <motion.button whileHover={{
                                scale: 1.1,
                            }} type={"button"} className={"circleAction"} onClick={() => {
                                removeUser(3);
                            }}>X
                            </motion.button>
                        </div>)}
                    {count >= 4 && (
                        <div>
                            <input type={"text"}
                                   className={"font-secondary-small font-color-primary"}
                                   placeholder={"Guest Name"}
                                   value={getEntry(4)}
                                   onChange={(e) => {
                                       adjust(4, e.target.value)
                                   }}
                            />
                            <motion.button whileHover={{
                                scale: 1.1,
                            }} type={"button"} className={"circleAction"} onClick={() => {
                                removeUser(4);
                            }}>X
                            </motion.button>
                        </div>)}
                    {count === 5 && (
                        <div>
                            <input type={"text"}
                                   className={"font-secondary-small font-color-primary"}
                                   placeholder={"Guest Name"}
                                   value={getEntry(5)}
                                   onChange={(e) => {
                                       adjust(5, e.target.value)
                                   }}
                            />
                            <motion.button whileHover={{
                                scale: 1.1,
                            }} type={"button"} className={"circleAction"} onClick={() => {
                                removeUser(5);
                            }}>X
                            </motion.button>
                        </div>)}
                    {count !== 5 && (
                        <div className={"left font-secondary font-color-primary"}>
                            <motion.button whileHover={{
                                scale: 1.1,
                            }} type={"button"} disabled={validCount !== count} className={"circleAction"}
                                           onClick={addUser}>+
                            </motion.button>
                            Add Guest
                        </div>)}
                    <motion.button whileHover={{
                        scale: 1.1,
                    }} type={"submit"} disabled={validCount !== count} className={"submitButton"}>Submit
                    </motion.button>
                </form>
            </div>
        )
    }
}

export default RSVP;