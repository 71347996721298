import * as rdd from 'react-device-detect';
class session {
    sessionId: string;
    sessionStartedUTC: string;
    sessionStarted: string;
    sessionTimeZone: number;
    deviceType: "B" | "M" | "U";
    browserType: string;
    osName: string;
    deviceDetails: any;
    constructor() {
        this.sessionStartedUTC = new Date().toUTCString();
        this.sessionStarted = new Date().toLocaleString();
        this.sessionTimeZone = new Date().getTimezoneOffset();
        this.deviceType = rdd.isBrowser ? "B" : rdd.isMobile ? "M" : "U";
        this.browserType = rdd.browserName;
        this.osName = rdd.osName;
        this.deviceDetails = rdd.deviceDetect(rdd.getUA);
        this.sessionId = new Date().toUTCString().concat(this.browserType, this.osName, this.browserType);
    }
    getCurrent(status: string,) {
       return {
           session: this.sessionId,
           sessionLength:  Date.parse(this.sessionStartedUTC)- Date.now(),
           status: status
       }
    }
    getSessionTime() {
        return Date.now() - Date.parse(this.sessionStartedUTC);
    }

    getFinal(status: string) {
        return {
            session: this.sessionId,
            sessionLength: Date.now() - Date.parse(this.sessionStartedUTC),
            status: status,
            endUTC: new Date().toUTCString(),
            endLocal: new Date().toLocaleString(),
            startUTC: this.sessionStartedUTC,
            startLocal: this.sessionStarted,
            clientTimezone: this.sessionTimeZone,
            deviceType: this.deviceType,
            browser: this.browserType,
            clientOS: this.osName,
            client: this.deviceDetails
        }
    }
}
export default session;
