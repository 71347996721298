import {motion} from "framer-motion";
import "./ViewStyle.css"
import "./LandingViewStyle.css"
import React from "react";
const LocationView = () => {
    function getVariants(order: number){
        let delay = .5 + (order * .1);
        return ({
            visible: {
                opacity: 1,
                x: 0,
                transition: {
                    delay: delay,
                    type: "spring",
                    damping: 12,
                    stiffness: 100,
                },
            },
            hidden: {
                opacity: 0,
                x: 20,
                transition: {
                    type: "spring",
                    damping: 12,
                    stiffness: 100,
                },
            },
        })
    }
    return (
            <div className={"center"}>
                <p className={"font-caption font-color-primary"}>You're Invited to</p>
                <div id={"landing-caption"}>
                    <span style={{display:"flex", flexDirection:"row", justifyContent: "space-between"}}>
                        <motion.p
                            className={"font-caption-large font-color-secondary"}
                            variants={getVariants(1)}
                            initial="hidden"
                            animate="visible"
                        >Our</motion.p>&nbsp; &nbsp;
                        <motion.p
                            className={"font-caption-large font-color-secondary"}
                            variants={getVariants(2)}
                            initial="hidden"
                            animate="visible"
                        >Baby</motion.p>
                    </span>
                    <br/>
                    <motion.p
                        className={"font-caption-large font-color-secondary"}
                        variants={getVariants(3)}
                        initial="hidden"
                        animate="visible"
                    >Shower</motion.p>
                </div>
                <p className={"font-caption font-color-primary"}>Nov. 18th 2023</p>
            </div>
    )
}
export default LocationView;