import session from "./userSession";
import {messageLayout} from "../pages/views/RSVP"

type RSVP_RESPONSES = "SUCCESS" | "FAILED" | "ERROR";
class webService {
    clientSession: session;
    status: boolean;
    constructor() {
        this.clientSession = new session();
        this.status = false;
    }
    public sendRSVP(details: messageLayout): Promise<RSVP_RESPONSES>{
        return fetch("/api/rsvp",
            {
                method: 'POST',
                body: JSON.stringify(this.buildMessage(false, this.status, details)),
                headers: {
                    "content-type": "application/json"
                }
            }).then((response) => {
                this.status = response.ok;
                return response.ok ? "SUCCESS" : "FAILED";
        }).catch((ops) => {
            return "ERROR";
        });
    }
    buildMessage(final: boolean, state: boolean, message: any) {
        let status = state ? "RSVP SUCCESSFULLY" : final ? "RSVP NOT SUBMITTED" : "RSVP PENDING";
        let body = {
            session: this.clientSession.getFinal(status),
            message : message
        }
        console.log(body);
        return body;
    }
}
export default webService